.authModule {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 400px;
    margin: auto;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.overlay {
    height: 100%;
    padding: 12px 4px 16px;
}

.msitePageHeader {
    justify-content: flex-start;
}

.dialog {
    padding: 32px;
}

.newDialog {
    padding: 24px;
    max-height: calc(100vh - 48px);

    overflow-x: hidden;
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        width: 0;
    }
}

.blur {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}

.autoLoginBlur {
    overflow: clip;
    background: rgba(255, 255, 255, 0.5);
}
